const initialState = {
  action: null
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {

      case 'NAV_BAR/ACTION':
        return Object.assign({}, state, action.payload);
        case 'NAV_BAR/CLEAN_ACTION':
          return Object.assign({}, state, { action: null});
      default:
        return state
    } 
  }