import { createAction } from "../util/create_action";
import { userApi } from "./../../api/UserApi";
import Localstore from "./../../util/Localstore";

export const startSession = (pEmail, pToken) => {
    return {
        type: "USER/START_SESSION",
        payload: {
            email: pEmail,
            token: pToken
        }
    }
}

export const saveUser = (pUserId, pLastName, pFirstName, pAccount, pTypes, pAppVer) => {
    return {
        type: "USER/SAVE",
        payload: {
            userId: pUserId,
            lastname: pLastName,
            firstname: pFirstName,
            account: pAccount,
            types: pTypes,
            appver: pAppVer
        }
    }
}

export function login(pEmail, pPassw, pApp) {
    return async function (dispatch, getState) {
        let vResponse = await userApi.login(pEmail, pPassw, pApp);

        if (vResponse.data.success) {
            Localstore.saveToken(pApp, vResponse.data.data);
        }

        return vResponse.data;
    }
}

export function getUser(pTkn) {
    return async function (dispatch, getState) {
        let vTkn = pTkn ? pTkn : getState().get('user').token;
        let vResponse = await userApi.get(vTkn);

        return vResponse.data;
    }
}

export const saveToken = (pTkn) => {
    return {
        type: "USER/SAVE_TOKEN",
        payload: pTkn
    }
}

export const validateToken = createAction(
    "USER/VALIDATE_TOKEN",
    async (pTkn) => {
        let vResponse = await userApi.validateToken(pTkn);

        return vResponse.data;
    }
);

export function clearToken(pApp) {
    Localstore.clearToken(pApp);
    return {
        type: "USER/CLEAR_TOKEN",
        payload: {}
    }
}

export const activeAccount = createAction(
    "USER/ACCOUNT_ACTIVE",
    async (pTkn, pAccountId) => {
        let vResponse = await userApi.activeAccount(pTkn, pAccountId);

        return vResponse.data;
    }
);