import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';

import reducer from './../../redux/reducers/index';
import { clearToken } from './actions/User';

function stringTypeAction() {
  return next => action => {
    if (typeof action === 'string') {
      action = { type: action }
    }

    return next(action)
  }
}

function tokenAction() {
  return next => action => {

    let vResponse = next(action);

    if (Promise.resolve(vResponse) === vResponse) {
      return new Promise((accept) => {
        vResponse.then((pData) => {
          if (!pData.success) {
            switch (pData.msg) {
              case "authorization_token_expirated":
                dispatch(clearToken());
                break;
              case "authorization_token_invalid":
                dispatch(clearToken());
                break;
              default:
              //   dispatch(clearToken());
            }
          }
          accept(pData);
        })
      });
    } else {
      return vResponse;
    }
  }
}

// export const store = createStore(
//     reducer
// )


export const store = createStore(reducer, composeWithDevTools(applyMiddleware(stringTypeAction, tokenAction, thunkMiddleware)));

export const dispatch = store.dispatch.bind(store)
export const getState = store.getState.bind(store)
