import { baseApi } from "./BaseApi";

export class UserApi {

    async login(pEmail, pPassw, pApp) {

        let vParams = { email: pEmail, password: pPassw };

        // if exists App, check login by app
        if (pApp) {
            vParams.type = pApp;
        }

        
        return baseApi.post("/user/login", vParams);
        //return baseApi.post("/user/login", { email: pEmail, password: pPassw, type: "ssa" });
    }

    async get(pTkn) {
        return baseApi.post("/user/get", {}, { token: pTkn });
    }

    async validateToken(pTkn) {
        return baseApi.post("/user/token", {t:"."}, { token: pTkn });
    }

    async activeAccount(pTkn, pAccountId) {
        return baseApi.post("/user/account/active", {accountid: pAccountId}, { token: pTkn });
    }

}

export const userApi = new UserApi();