import {
  BrowserRouter as Router,
  Switch
} from "react-router-dom";
import React from 'react';
import { Provider } from 'react-redux'

import { store } from './shared/redux/store';

import Switcher from "./routers/Switcher";

import Configuration from "./config/Configuration";

class App extends React.Component {
  render() {
    return (
      <Provider store={store} >
        <Router history={this.props.history} basename={Configuration.basename}>
            <Switch>
              <Switcher></Switcher>
            </Switch>
        </Router>
      </Provider>
    );
  }
}

export default App;
