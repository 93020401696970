import {
    Route
} from "react-router-dom";
import React from 'react';

import { connect } from 'react-redux'

import { getUser } from './../shared/redux/selectors/User';

// ------------------- ROUTES -----------------------
import Login from './../shared/routers/login/Login'
import LoaderWApp from "./loader/LoaderWApp";
import Logout from "./loader/Logout";
// ------------------- ROUTES -----------------------

// ------------------- LAYOUTS -----------------------
import GeneralLayout from './../shared/layouts/GeneralLayout';
// ------------------- LAYOUTS -----------------------

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => {
    return <Route {...rest} render={props => (
        <Layout history={props.history} path={props.match.path}>
            <Component {...props} />
        </Layout>
    )} />;
}

class Switcher extends React.Component {
    render() {
        return (
            <>
                <AppRoute exact path="/logout" layout={GeneralLayout} component={Logout}></AppRoute>

                <AppRoute exact path="/launch" layout={GeneralLayout} component={LoaderWApp}></AppRoute>

                <AppRoute path="/" layout={GeneralLayout} component={Login}></AppRoute>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: getUser(state)
    }
}
export default connect(mapStateToProps)(Switcher);
