 
import { dispatch } from './../store';

export function createAction(name, ...args) {
    const originalAction = args.pop()
    const middleware = args

    if (!originalAction) {
        throw new Error('createAction() requiere como minimo 2 argumentos (nombre, fn)')
    }

    const action = applyMiddleware(wrapAction(name, originalAction), middleware)

    return namedFunction(name, action)
}

function applyMiddleware(actionCreator, middleware) {
  const withMiddleware = middleware.reduceRight(
    (fn, nextMiddleware) => nextMiddleware(fn),
    actionCreator
    )

    return (...args) => () => withMiddleware(...args)
}

function wrapAction(name, action) {
  return async function(...args) {
    dispatch(`${name}:START`)

    try {
      const result = await action(...args)
        dispatch({ type: `${name}:SUCCESS`, payload: result })
        return result
      } catch (error) {
          if (error.isGlobal) {
              //handleGlobalError(error)
              dispatch({ type: `${name}:ERROR`, error })
          } else throw error
      } finally {
          dispatch(`${name}:END`)
      }
  }
}

function namedFunction(name, fn) {
    let vName = name.replace("/", "_");
    return new Function (
        'fn', // 1st argument, passed below
        `return function ${vName} () { return fn.apply(this, arguments) }`
    )(fn);
    // Source:
    // http://marcosc.com/2012/03/dynamic-function-names-in-javascript/
    // http://stackoverflow.com/questions/5905492/dynamic-function-name-in-javascript
}