import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import reportWebVitals from './reportWebVitals';
import history from './shared/util/history';

// import 'bootstrap/dist/css/bootstrap.min.css';


function montarApp() {
  const app = document.getElementById('top')

  ReactDOM.render(<App history={history} />, app)
}

export function inicializar() {
  // if (!restaurarAuth()) {
  //   montarApp()
  // }
  // restaurarAuth(function(pApp) {
    montarApp();
  // });
  
}

inicializar()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
