import React, { Component } from 'react';
import { connect } from 'react-redux'
import { clearToken } from '../../shared/redux/actions/User';

import { getUser } from '../../shared/redux/selectors/User';
import { dispatch } from '../../shared/redux/store';

import Logger from '../../shared/util/Logger';

class Logout extends Component {
  constructor(props) {
    super(props);

    this._logger = new Logger("Logout");

    this.state = {
    }
  }

  componentDidMount() {
    console.log("User to logout: ", this.props.user);

    dispatch(clearToken());

    this.props.history.push("/");
  }

  render() {

    return <></>;

  }
}

const mapStateToProps = (state) => {
  return {
      user: getUser(state)
  }
}
export default connect(mapStateToProps)(Logout);
