import React from 'react';

import './GeneralLayout.css';

class GeneralLayout extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        let { path } = this.props;

        console.log("PATH -> ", path);

        return (
            <>
                <div id="top" className="header_wrapper">
                    <header id="header" className="header">
                        <div className="container">
                            <div className="row">
                                <div className="header-front">
                                    <div className="logo">
													{/* <div className="beta">BETA</div> */}
											  </div>
                                    <div className="label-studio">{""}</div>
                                </div>
                            </div>
                        </div>
                    </header>
                </div>
                <div className="main_wrapper">
                    {this.props.children}
                </div>
            </>
        )
    }
}


export default GeneralLayout;