import { Component } from 'react';
import { connect } from 'react-redux'
import Configuration from '../../config/Configuration';

import { getUser } from '../../shared/redux/selectors/User';

import Logger from '../../shared/util/Logger';

class LoaderWApp extends Component {
  constructor(props) {
    super(props);

    this._logger = new Logger("LoaderWApp");

    this.state = {
    }
  }

  componentDidUpdate() {
    if (!this.props.user.token) {
      this.props.history.push("/login");
      return null;
    }
  }

  render() {

    window.location.href = Configuration.webapp.url + 
      (Configuration.env === "dev" ? Configuration.webapp.loginService + this.props.user.token : (this.props.user.appver<100 ? (""+this.props.user.appver).padStart(3,'0') : this.props.user.appver) );
    return null;

    // return <b>load page</b>

  }
}

const mapStateToProps = (state) => {
  return {
      user: getUser(state)
  }
}
export default connect(mapStateToProps)(LoaderWApp);
