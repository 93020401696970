import { createBrowserHistory } from 'history';

const history = createBrowserHistory()

// During an async action, a synchronous push to the history stack results
// in UPDATE_LOCATION arriving before :END actions. This is easily solved by
// delaying the push until the next tick:
history.pushLater = (...args) => setImmediate(() => history.push(...args))

export default history
