
import Config from './../../config/Configuration';

class Logger {
    constructor(pClassName) {
        this._reqId = (Math.random() * 10000000).toFixed(0);

        this._className = pClassName;
        
        var levels = ['log', 'dir', 'error', 'count', 'info', 'debug'];

        let vThat = this;
        levels.forEach(function (level) {
            vThat[level] = function () {
                if (Config.logger.enable) {
                    let vArgs = [].slice.call(arguments);
                    //vArgs.unshift('[' + vThat._reqId + ' - ' + vThat._className + '] ');
                    vArgs[0] = '[' + vThat._reqId + ' - ' + vThat._className + '] ' + vArgs[0];
                    console[level].apply(console, vArgs);
                }
            };
        });
    }
}


export default Logger;