
const initialState = {
  email: null,
  token: null,
  startSession: null,
  lastname: null,
  firstname: null,
  appver: ""
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'USER/START_SESSION':
      return Object.assign({}, state, {
        email: action.payload.email,
        token: action.payload.token,
        startSession: new Date()
      });

    case 'USER/SAVE_TOKEN':

      return Object.assign({}, state, {
        token: action.payload
      });

    case 'USER/SAVE':

      return Object.assign({}, state, {
        lastname: action.payload.lastname,
        firstname: action.payload.firstname,
        userId: action.payload.userId,
        appver: action.payload.appver
      });

    case 'USER/CLEAR_TOKEN':

      return {
        email: "",
        token: null,
        startSession: null,
        lastname: null,
        firstname: null,
        userId: null,
        appver: null
      };

    case 'USER/VALIDATE_TOKEN:START':
      return Object.assign({}, state, {
        validating: true
      });

    case 'USER/VALIDATE_TOKEN:END':
      return Object.assign({}, state, {
        validating: false
      });

    default:
      return state
  }
}