import Config from "./../../config/Configuration";
import axios from "axios";

export class BaseApi {

    post(pUrl, pData, pOptions) {

        let vOptions = {
            withCredentials: true,
            'Content-Type': 'application/json'
        }
        if (pOptions && pOptions.token) {
            vOptions.headers = {
                Authorization: 'Bearer ' + pOptions.token
            }

        }

        let vCheckObj = function isObject(obj)
        {
            return obj !== undefined && obj !== null && obj.constructor == Object;
        }

        let vParams = new FormData();
        for (let vProp in pData) { 
            vParams.append(vProp, vCheckObj(pData[vProp]) ?  JSON.stringify(pData[vProp]) : pData[vProp]);
        }

        return axios.post(Config.flexServerStudio.url + pUrl, pData, vOptions);

    }
}

export const baseApi = new BaseApi();