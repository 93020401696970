import React from 'react';
import "./style.css"
import Select from 'react-select'

class NormalSelectCmp extends React.Component {

	constructor(props) {
		super(props);
		this.props = props;
		this.state = {};
	}

	onChange = (pOption) => {
		console.log("Select ", pOption);
		if (typeof this.props.NormSelConst.callback === "undefined") return;
		this.props.NormSelConst.callback(this.props.NormSelConst.name, pOption);
	}

	render() {

		let vClassName = this.props.className ? this.props.className : "fs_normal_select";



		return (
			<div className={"fs_normal_common " + vClassName}>
				<Select
					options={this.props.NormSelConst.items}
					placeholder={this.props.NormSelConst.placeholder ? this.props.NormSelConst.placeholder : "Select"}
					isSearchable={false}
					onChange={this.onChange}
					value={this.props.NormSelConst.selected}
					theme={theme => ({
						...theme,
						borderRadius: 3,
						colors: {
							...theme.colors,
							primary25: '#8998b6',
							primary: '#ccc',
						},
						cursor: "pointer"
					})}
				/>
			</div>

		)
	}
};

export default NormalSelectCmp;