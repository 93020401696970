
const LocalStore = {
  
    saveToken(pApp, token) {
      localStorage.setItem('fcs_' + (pApp ? pApp : "wapp") + '_tkn', token)
    },
  
    getToken(pApp) {
      return localStorage.getItem('fcs_' + (pApp ? pApp : "wapp") + '_tkn')
    },
  
    clear(pApp) {
      localStorage.removeItem('fcs_' + (pApp ? pApp : "wapp") + '_tkn')
    },

    clearToken(pApp) {
        localStorage.removeItem('fcs_' + (pApp ? pApp : "wapp") + '_tkn')
      }
  };

export default LocalStore;
  